var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            placeholder: "请输入创建人",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
            clearable: "",
          },
          on: { change: _vm.handleSelectChange },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _c(
        "el-checkbox",
        {
          on: { change: _vm.handleCheckboxChange },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        },
        [_vm._v("只看自己")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }